<template>
  <div class="enterprise">
    enterprise
  </div>
</template>

<script>
export default {
  name: 'enterprise',
  props: {
    
  }
}
</script>

<style lang="scss">
  .enterprise {
    
  }
</style>
